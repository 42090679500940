@tailwind base;
@tailwind component;
@tailwind utilities;

@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
    }
  }

html,
body {
    overflow: hidden;
} 

body {
    font-family: 'Lato', sans-serif;
    margin: 0;
    padding: 0;
    border: 0;
    overflow-y:hidden !important;
    background-color: transparent;
    /* height: 100%; */
}

.unslct {
  user-select: none;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.Toastify {
    background-color: transparent;
}

.hide-scrollbar::-webkit-scrollbar{
    display: none;
}

.main-bg {
    background-color: #7CC6FE;;
}

.faded {
    background-image: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 70%,
    rgba(0, 0, 0, 0.7) 80%,
    rgba(0, 0, 0, 0.8) 90%,
    rgba(0, 0, 0, 0.8) 95%
);
background-blend-mode: screen;
}

.ReactModal__Content {
    position: absolute; 
    width: 80%;
    height: 70%;
    overflow: auto;
    background-color: #fff;
    -webkit-transform: translate(-50,-50%);
    transform: translate(-50,-50%);
}

.page {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .page img {
    max-width: 80%;
    max-height: 80%;
    margin-top: 20px;
  }

  .ReactModal__Overlay{
    z-index: 20;
  }

  .slide-fwd {
    animation: slideFwd 0.5s ease-in-out forwards;
  }
  
  @keyframes slideFwd {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .page {
      font-size: 18px;
    }
  }

@media print {
    .pdf-container {
        display: none;
    }
}

/* @media (orientation: landscape) {
    html, body {
      width: 100vh;
      height: 100vw;
      overflow: hidden;
      position: fixed;
      transform-origin: top left;
      transform: rotate(90deg);
    }
  } */