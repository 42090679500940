.popup {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #3e3e3e;
    color: white;
    padding: 10px;
    border-radius: 5px;
    display: none;
    transition: opacity 0.3s ease-in-out;
  }
  
  .popup.active {
    display: block;
    opacity: 1;
  }
  